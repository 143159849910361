import React from 'react';

import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { triggerScroll } from '@/Utils/targetMessageService';
import { isWebSocketConnected } from '@/Utils/webSocketUtils';
import { setActiveServer } from '@/store/server-data-reducer/server-data-reducer';

import useChatHelper from './useChatHelper';
import { useAppDispatch, useAppSelector } from './useRedux';

type Props = {};

const useNotificationHelper = () => {
    const dispatch = useAppDispatch();
    const activeServer = useAppSelector(
        state => state.serverDataReducer.activeServer,
    );

    const joinChannelHandler = data => {
        if (data.subserver_id && data.channel_id) {
            if (data.subserver_id.trim() !== activeServer) {
                dispatch(setActiveServer(data.subserver_id));
                setTimeout(() => {
                    setTimeout(() => {
                        isWebSocketConnected() &&
                            onJoinChannel(data.channel_id);
                    }, 500);
                    triggerScroll(data);
                }, 500);
            } else {
                isWebSocketConnected() && onJoinChannel(data.channel_id);
                // props?.onScrollIntoMessage(data._id);
                setTimeout(() => {
                    triggerScroll(data);
                }, 500);
            }
        }
    };
    return { joinChannelHandler };
};

export default useNotificationHelper;
