import moment from 'moment-timezone';
import {
    CSSProperties,
    Dispatch,
    FC,
    ReactNode,
    RefObject,
    SetStateAction,
    createContext,
    useEffect,
    useRef,
    useState,
} from 'react';

import { getChannelMemberList } from '@/Utils/memberUtils';
import { onJoinChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import { handleOpenThread } from '@/Utils/socket-actions/ThreadActions/threadActions';
import { triggerScroll } from '@/Utils/targetMessageService';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { indexedDBSearchActions } from '@/store/search-reducer/indexed-db-search-reducer.js';
import {
    activeChannelIdSelector,
    channelDataSelector,
    memberDataSelector,
    sectionDataSelector,
    serverDataSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import { IChannelData, IMemberData } from '@/types';
import { IUser } from '@/types/slices/UserTypes';

interface IProps {
    children: ReactNode;
    from?: string;
    onClose: () => void;
}

interface ISearchContext {
    bodyStyle: CSSProperties;
    isFromChatAreaSection: undefined | boolean;
    searchRef: RefObject<HTMLInputElement>;
    searchResult: any[];
    searchText: string;
    pageResult: any[];
    searchType: string;
    userModal: { userData: any; state: boolean };
    setUserModal: Dispatch<SetStateAction<{ userData: any; state: boolean }>>;
    joinChannelHandler: (channelId: string) => void;
    clickMessageHandler: (data: any) => void;
    ChangeSearchValueHandler: (searchValue: string) => void;
    ChangeChatAreaSearchHandler: (trimmedKey: string) => void;
    changePageHandler: (slicedArray: []) => void;
    closeModal: () => void;
    members: IMemberData[];
    user: IUser;
    channel: IChannelData[];
}

const initalContextValues: ISearchContext = {
    bodyStyle: {},
    isFromChatAreaSection: undefined,
    searchRef: { current: null },
    searchResult: [],
    searchText: '',
    pageResult: [],
    searchType: '',
    userModal: { userData: {}, state: false },
    setUserModal: () => {},
    joinChannelHandler: () => {},
    clickMessageHandler: () => {},
    ChangeSearchValueHandler: () => {},
    ChangeChatAreaSearchHandler: () => {},
    changePageHandler: () => {},
    closeModal: () => {},
    members: null,
    user: null,
    channel: null,
};

export const SearchContext = createContext<ISearchContext>(initalContextValues);

const SearchProvider: FC<IProps> = ({ children, from, onClose }) => {
    let timeout: NodeJS.Timeout | null = null;

    const SEARCH_ON_CHAT_SERVER = true;

    const dispatch = useAppDispatch();

    const searchRef = useRef<HTMLInputElement>(null);
    const isFromChatAreaSection = from === 'ChatAreaSection';

    const bodyStyle = {
        height: 'max(80vh, 400px)',
    };

    const searchData = useAppSelector(
        state => state.indexedDBReducer.searchResults,
    );

    const [searchText, setSearchText] = useState('');
    const [searchType, setSearchType] = useState('');
    const [localMsgState, setLocalMsgState] = useState<any[]>([]);
    const [pageResult, setPageResult] = useState<any[]>([]);
    const [searchResult, setSearchResult] = useState<any[]>(searchData);
    const [userModal, setUserModal] = useState(initalContextValues.userModal);

    const activeChannelId = useAppSelector(activeChannelIdSelector);
    const sections = useAppSelector(sectionDataSelector);
    const members: IMemberData[] = useAppSelector(state =>
        memberDataSelector(state),
    );
    const channel: IChannelData[] = useAppSelector(state =>
        channelDataSelector(state),
    );
    const timeZone = useAppSelector(state => state.utilReducer.timeZone);
    const serversData = useAppSelector(state => serverDataSelector(state));
    const user: IUser = useAppSelector(state => state.userReducer.user);

    const indexedDBSelector = useAppSelector(
        state => state.indexedDBReducer.db,
    );

    const cacheMessages = useAppSelector(
        state => state.messageReducer.messagesList,
    );

    const joinChannelHandler = (channelId: string) => {
        onJoinChannel(channelId);
        onClose();
    };

    const changePageHandler = (slicedArray: any[]) => {
        setPageResult(slicedArray);
    };

    const closeModal = () => {
        dispatch(indexedDBSearchActions.setSearchResults([]));
        onClose();
    };

    const clickMessageHandler = (data: any) => {
        if (data.channel_id !== activeChannelId && !data?.thread_id) {
            onJoinChannel(data.channel_id);
        } else {
            handleOpenThread(data?.thread_id);
        }
        data._id = data?.message_id; // fix converted message_id -> _id
        onClose();
        dispatch(indexedDBSearchActions.setSearchResults([]));
        setTimeout(() => {
            triggerScroll(data);
            // props.onScrollIntoMessage(data);
        }, 500);
    };

    const removePrefix = (value: string) => {
        return {
            newValue: value.substring(1),
        };
    };

    function removeDuplicatesById(array: any[]) {
        const seenIds = new Set();
        const uniqueArray = [];

        for (const obj of array) {
            if (!seenIds.has(obj._id)) {
                seenIds.add(obj._id);
                uniqueArray.push(obj);
            }
        }
        return uniqueArray;
    }

    // search inside indexedDB array for a specific number of slice
    const loadBlock = (blockNumber: number, trimedKey: string) => {
        const block = indexedDBSelector.slice(blockNumber, blockNumber + 39);
        let dbFilteredMessages: any[] = [];

        block.forEach(element => {
            if (element.message == '' && element.message?.message !== '') {
                if (element.message?.content) {
                    if (
                        element.message.content
                            .toLowerCase()
                            .includes(trimedKey)
                    ) {
                        dbFilteredMessages.push(element);
                    }
                } else if (element.message?.message) {
                    if (
                        element.message?.message
                            ?.toLowerCase()
                            .includes(trimedKey)
                    ) {
                        dbFilteredMessages.push(element);
                    }
                } else if (
                    element.message &&
                    !element.message?.message &&
                    !element.message?.content &&
                    !element.message?.replyTo
                ) {
                    if (element.message?.toLowerCase()?.includes(trimedKey)) {
                        dbFilteredMessages.push(element);
                    }
                }
            }
        });
        return { dbFilteredMessages };
    };

    const searchMessagesChatServer = (trimmedKey: string) => {
        const payload = {
            action: 'search',
            data: trimmedKey.replace(/I/g, 'i'),
        };

        const ChatAreapayload = {
            action: 'search',
            data: trimmedKey.replace(/I/g, 'i'),
            channel_ids: [activeChannelId],
        };

        sendWebSocketData(
            isFromChatAreaSection
                ? JSON.stringify(ChatAreapayload)
                : JSON.stringify(payload),
        );
    };

    const searchForMessages = (trimedKey: string) => {
        setTimeout(() => {
            setSearchResult([]);
            if (trimedKey !== '') {
                setSearchType('Messages');
                let filteredLocals: any[] = [];
                try {
                    localMsgState?.forEach(e => {
                        if (e.message?.content) {
                            e.message.content
                                .toLowerCase()
                                .includes(trimedKey) && filteredLocals.push(e);
                        } else {
                            e?.message.toLowerCase().includes(trimedKey) &&
                                filteredLocals.push(e);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }

                let DBBlockData: any[] = [];
                for (
                    let chunkIndex = 0;
                    chunkIndex <= indexedDBSelector.length;
                    chunkIndex += 40
                ) {
                    const { dbFilteredMessages } = loadBlock(
                        chunkIndex,
                        trimedKey,
                    );
                    DBBlockData.push(...dbFilteredMessages);
                    setSearchResult(prev => [...prev, ...dbFilteredMessages]);
                }

                const localAndDB = [...filteredLocals, ...DBBlockData];

                let removedDuplicates = removeDuplicatesById(localAndDB);

                removedDuplicates.map(e => {
                    return {
                        ...e,
                        owner: members.find(
                            el => el.id === e?.user_id ?? e?.sender_id,
                        ),
                    };
                });

                setSearchResult(removedDuplicates);
            }
        }, 150);
    };

    const searchForUsers = (trimedKey: string) => {
        setUserModal({
            userData: {},
            state: false,
        });
        setTimeout(() => {
            const { newValue } = removePrefix(trimedKey);
            if (newValue !== '') {
                const filterUsers = members.filter(user =>
                    user?.name?.toLowerCase()?.includes(newValue),
                );
                setSearchResult(filterUsers);
            } else {
                setSearchResult([]);
            }
        }, 100);
    };

    const searchForChannels = (trimedKey: string, type: string) => {
        setTimeout(() => {
            const { newValue } = removePrefix(trimedKey);
            if (newValue !== '') {
                const textChannels = channel.filter(
                    ch =>
                        ch.type === type &&
                        ch.privacy === 'channel' &&
                        ch?.name?.toLowerCase()?.includes(newValue),
                );

                const sectionsWithChannels = textChannels?.map(ch => {
                    const section = sections?.find(val => {
                        return val?.channelList?.some(
                            channel => channel?._id === ch?._id,
                        );
                    });

                    return {
                        ...ch,
                        section: section?.name,
                    };
                });

                if (sectionsWithChannels.length > 0) {
                    setSearchResult(sectionsWithChannels);
                } else {
                    setSearchResult(textChannels);
                }
            } else {
                setSearchResult([]);
            }
        }, 100);
    };

    const searchForServers = (trimedKey: string) => {
        setTimeout(() => {
            const { newValue } = removePrefix(trimedKey);

            if (newValue !== '') {
                const filterServers = serversData.filter(server =>
                    server?.name?.toLowerCase().includes(newValue),
                );
                setSearchResult(filterServers);
            } else {
                setSearchResult([]);
            }
        }, 100);
    };

    const ChangeSearchValueHandler = (search: string) => {
        setSearchText(search);
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchResult([]);
            const trimedKey = search.trim();
            switch (true) {
                // search for usernames
                case trimedKey.startsWith('@'):
                    setSearchType('Users');
                    searchForUsers(trimedKey);
                    break;
                case trimedKey.startsWith('#'):
                    setSearchType('Text Channels');
                    searchForChannels(trimedKey, 'text');
                    break;
                case trimedKey.startsWith('!'):
                    setSearchType('Voice Channels');
                    searchForChannels(trimedKey, 'voice');
                    break;
                case trimedKey.startsWith('*'):
                    setSearchType('Servers');
                    searchForServers(trimedKey);

                    break;
                default:
                    SEARCH_ON_CHAT_SERVER
                        ? searchMessagesChatServer(trimedKey)
                        : searchForMessages(trimedKey);
                    setSearchType('Messages');
                    break;
            }
        }, 500);
    };

    const ChangeChatAreaSearchHandler = (trimedKey: string) => {
        setSearchText(trimedKey);
        if (trimedKey.startsWith('@')) {
            setSearchType('Users');
            setUserModal({
                userData: {},
                state: false,
            });

            setTimeout(() => {
                const { newValue } = removePrefix(trimedKey);
                if (newValue !== '') {
                    const members = getChannelMemberList(activeChannelId);
                    const filterUsers = members.filter(user =>
                        user?.name?.toLowerCase()?.includes(newValue),
                    );
                    setSearchResult(filterUsers);
                } else {
                    setSearchResult([]);
                }
            }, 100);
        } else {
            setSearchType('Messages');
            searchMessagesChatServer(trimedKey);
        }
    };

    useEffect(() => {
        setSearchResult(searchData);
    }, [searchData]);

    useEffect(() => {
        if (timeZone && searchData) {
            const updatedData = searchData.map(val => {
                // const utcOffset = moment.tz.zone(timeZone).utcOffset(moment());
                const utcOffset = moment.tz
                    .zone(timeZone)
                    .utcOffset(moment().valueOf());

                const specificTime = moment.tz(val?.created_at, 'UTC');
                const localTime = specificTime.clone().local();

                const utcTime = specificTime.clone().utcOffset(utcOffset);

                const localTimeStr = localTime.format('YYYY-MM-DD HH:mm:ss z');
                const utcTimeStr = utcTime.format('YYYY-MM-DD HH:mm:ss UTC');

                return {
                    ...val,
                    created_at: localTimeStr,
                    utc_created_at: utcTimeStr,
                };
            });

            setSearchResult(updatedData);
        }
    }, [timeZone, searchData]);

    useEffect(() => {
        let localMessages: any[] = [];

        for (let key in cacheMessages) {
            for (let index in cacheMessages[key]) {
                localMessages.push(cacheMessages[key][index]);
            }
        }
        setLocalMsgState(localMessages);
    }, [cacheMessages]);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current.focus();
        }
    }, []);

    const providerValues: ISearchContext = {
        bodyStyle,
        isFromChatAreaSection,
        searchRef,
        searchResult,
        searchText,
        pageResult,
        searchType,
        userModal,
        closeModal,
        setUserModal,
        joinChannelHandler,
        clickMessageHandler,
        ChangeSearchValueHandler,
        ChangeChatAreaSearchHandler,
        changePageHandler,
        members,
        user,
        channel,
    };

    return (
        <SearchContext.Provider value={providerValues}>
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
