import { t } from '@lingui/macro';
import React from 'react';

import { sendMessage } from '@/Utils/socket-actions/MessageActions/messageActions';
import NewChat from '@/components/server-details-section/server-editor/new-chat/NewChat';
import { useAppSelector } from '@/hooks/useRedux';
import {
    memberDataSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';

type Props = {};

const RenderMessageModals = (props: any) => {
    const { isForward, setIsForward } = props;

    const {
        type,
        message,
        mediaFiles,
        user_id,
        _id,
        channel_mentions,
        role_mentions,
        channel_id,
        replyData,
        created_at,
        deleted_at,
        mention_id,
        reply_id,
    } = props.item;

    const channelListObj = useAppSelector(
        state =>
            state.serverDataReducer.channelList[
                state.serverDataReducer.activeServer
            ],
    );
    const userPermissions = useAppSelector(userPermissionSelector);
    const permissions = userPermissions;
    const membersList = useAppSelector(state => memberDataSelector(state));

    const channelListFilter = Object.values(channelListObj ?? {})?.filter(
        (item: { privacy: string }) => item?.privacy !== 'direct',
    );
    const tabs = [
        t`Channel`,
        permissions?.['pvt_message.create'] ? t`Private` : undefined,
    ];

    const tabBody = [
        channelListFilter,
        permissions?.['pvt_message.create'] && membersList,
    ];
    const submitForwardHandler = () => {
        const originalForwardMessage = {
            channel_id: channel_id,
            channel_mentions: channel_mentions,
            roles_mentions: role_mentions,
            created_at: created_at,
            deleted_at: deleted_at,
            mention_id: mention_id,
            message: message,
            reply_id: reply_id,
            type: type,
            user_id: user_id,
            _id: _id,
        };

        let payload = {
            type: 'forward',
            forwardedMessage: originalForwardMessage,
            message: props.item.message,
            mediaFiles: mediaFiles?.length > 0 ? mediaFiles : null,
            replyData: replyData ? replyData : null,
        };

        sendMessage('forward', payload, null);
    };
    return (
        <>
            {isForward && (
                <NewChat
                    isForward={true}
                    onClose={() => {
                        setIsForward(false);
                    }}
                    tabs={tabs.filter(item => item !== undefined)}
                    tabsBody={tabBody}
                    onSubmitForward={submitForwardHandler}
                />
            )}
        </>
    );
};

export default React.memo(RenderMessageModals);
