import { t } from '@lingui/macro';
import CryptoJS from 'crypto-js';
import { useState } from 'react';

import {
    RSA_CONSTANTS,
    encryptDataWithRSA,
    encryptPassword,
    generateRSAKeyPair,
    importKey,
} from '@/Utils/cryptoUtils';
import {
    getObjectExcludingKeys,
    joinObjects,
    sortObject,
    splitObjectValues,
} from '@/Utils/objectUtils';
import { editChannel } from '@/Utils/socket-actions/ChannelActions/channelActions';
import {
    handleEncryptKeyWithPassword,
    handleFileInputChange,
} from '@/Utils/uploadKeysUtils';
import { sendWebSocketData } from '@/Utils/webSocketUtils';
import { setPvtKeys } from '@/api/server';
import { MESSAGE_VERSION } from '@/constants';
import { SESSION_STORAGE } from '@/constants/enum';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
    channelDataSelector,
    serverDetailSelector,
    userPermissionSelector,
} from '@/store/server-data-reducer/selectors-server-data';
import {
    updatePrivateKey,
    updateServer,
} from '@/store/server-data-reducer/server-data-reducer';
import { updateDecryptionLoader } from '@/store/util-reducer';
import { IServerData } from '@/types';

const useEncryptionManagement = () => {
    const dispatch = useAppDispatch();

    const initialState = {
        fileName: '',
        privateKey: undefined,
        publiceKey: undefined,
        error: '',
        success: '',
    };

    const [files, setFiles] = useState<{
        fileName: string;
        privateKey?: JsonWebKey | undefined;
        publicKey?: JsonWebKey | undefined;
        error: string;
        success?: string;
    }>();

    const channelList = useAppSelector(channelDataSelector);
    const userPermissions = useAppSelector(userPermissionSelector);
    const serverData: IServerData = useAppSelector(state =>
        serverDetailSelector(state),
    );

    const generateKey = async () => {
        try {
            const keyPair = await generateRSAKeyPair();
            return keyPair;
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = () => {
        const setError = text => {
            return {
                fileName: '',
                privateKey: undefined,
                publicKey: undefined,
                error: text,
            };
        };
        handleFileInputChange(async (file, fileContent) => {
            if (file.type === 'application/json') {
                const jsonData: {
                    privateKey: JsonWebKey;
                    publicKey: JsonWebKey;
                } = JSON.parse(fileContent);
                try {
                    const cryptoKey = await importKey(
                        JSON.stringify(jsonData.privateKey),
                    );
                    if (cryptoKey)
                        setFiles({
                            fileName: file.name,
                            privateKey: jsonData.privateKey,
                            publicKey: jsonData.publicKey,
                            error: '',
                        });
                } catch (error) {
                    setFiles(
                        setError(
                            'Invalid Version File, Please upload version 1 file',
                        ),
                    );
                }
            } else setFiles(setError(t`Invalid Key File`));
        });
    };

    function downloadJSON(jsonString, fileName) {
        const fileType = 'application/json';
        const blob = new Blob([jsonString], { type: fileType });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(url);
    }

    const generateKeysJson = cryptoKeys => {
        const publicKey_parts = splitObjectValues(
            getObjectExcludingKeys(
                cryptoKeys.publicKey,
                Object.keys(RSA_CONSTANTS('encrypt')),
            ),
        );
        const privateKey_parts = splitObjectValues(
            getObjectExcludingKeys(
                cryptoKeys.privateKey,
                Object.keys(RSA_CONSTANTS('decrypt')),
            ),
        );

        const PUBLIC_KEY_USER = {
            ...publicKey_parts[0],
        };
        const PUBLIC_KEY_SERVER = { ...publicKey_parts[1] };

        const PRIVATE_KEY_USER = {
            ...privateKey_parts[0],
        };
        const PRIVATE_KEY_SERVER = { ...privateKey_parts[1] };

        const JSON_USER = JSON.stringify({
            publicKey: PUBLIC_KEY_USER,
            privateKey: PRIVATE_KEY_USER,
        });
        const JSON_SERVER = JSON.stringify({
            publicKey: PUBLIC_KEY_SERVER,
            privateKey: PRIVATE_KEY_SERVER,
        });

        return { JSON_USER, JSON_SERVER };
    };

    const updateServerRedux = async (newServerData, JSON_USER) => {
        sessionStorage.setItem('prev_privateKey', serverData.privateKey);
        sessionStorage.setItem('prev_publicKey', serverData.publicKey);

        const { publicKey, privateKey } = newServerData.pkey_parts;
        const userKeys = JSON.parse(JSON_USER);
        const publicKey_Complete = joinObjects(userKeys.publicKey, publicKey);
        const privateKey_Complete = joinObjects(
            userKeys.privateKey,
            privateKey,
        );
        const cryptoKey = await window.crypto.subtle.importKey(
            'jwk',
            { ...RSA_CONSTANTS('decrypt'), ...privateKey_Complete },
            { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
            false,
            ['decrypt'],
        );
        if (cryptoKey) {
            const { encrpytedPrivateKey, encrpytedPublicKey } =
                await handleEncryptKeyWithPassword(
                    privateKey_Complete,
                    publicKey_Complete,
                );

            dispatch(updateServer(newServerData));
            const hashKey = CryptoJS.MD5(
                JSON.stringify({
                    ...RSA_CONSTANTS('decrypt'),
                    ...privateKey_Complete,
                }),
            ).toString();
            dispatch(
                updatePrivateKey({
                    id: newServerData.id,
                    privateKey: encrpytedPrivateKey,
                    publicKey: encrpytedPublicKey,
                    defaultHashkey: hashKey,
                }),
            );
        }
    };

    const handleUpdateMessages = async () => {
        if (!userPermissions?.owner) return alert('You dont have permissions');
        return await handleMessagesMigration({
            message_encryption: MESSAGE_VERSION,
        });
    };

    const handleAddPrivateKeys = async () => {
        if (!userPermissions?.owner) return alert('You dont have permissions');

        if (files?.privateKey === undefined)
            return setFiles({ ...files, error: t`This field is required` });
        const { JSON_SERVER, JSON_USER } = generateKeysJson(files);
        downloadJSON(JSON_USER, `${serverData.name}_user_Keys.json`);

        const hash_key = CryptoJS.MD5(
            JSON.stringify({
                privateKey: sortObject(files?.privateKey),
                publicKey: sortObject(files?.publicKey),
            }),
        );
        const res = await setPvtKeys(serverData.id, JSON_SERVER);
        console.log(res);

        if (res.data) {
            const payload = {
                action: 'update_key_hash',
                data: { key_hash: hash_key.toString() },
            };

            sendWebSocketData(JSON.stringify(payload));

            const encrpytedPublic = await encryptPassword(
                files.publicKey,
                sessionStorage.getItem(SESSION_STORAGE.devicePassword),
            );
            await updateServerRedux(res.data, JSON_USER);
            await handleChannelEncryption(encrpytedPublic);
            await handleMessagesMigration({
                message_encryption: MESSAGE_VERSION,
            });

            setFiles({
                ...initialState,
                success: 'Re-Encrypting Channel Names',
            });

            setFiles({
                ...initialState,
                success: 'Private Keys updated Successfully',
            });
        }
    };

    // do not remove ----------
    const handleNewkeys = async () => {
        if (!userPermissions?.owner) return alert('You dont have permissions');

        sessionStorage.setItem('prev_privateKey', serverData.privateKey);
        const keyPair = await generateKey();
        if (keyPair) {
            const exportedKeys = await Promise.all([
                window.crypto.subtle.exportKey('jwk', keyPair.publicKey),
                window.crypto.subtle.exportKey('jwk', keyPair.privateKey),
            ]);

            const newPublicKey = exportedKeys[0],
                newPrivateKey = exportedKeys[1];
            const { JSON_SERVER, JSON_USER } = generateKeysJson({
                publicKey: newPublicKey,
                privateKey: newPrivateKey,
            });
            downloadJSON(JSON_USER, `${serverData.name}_user_Keys.json`);

            // const serverKeyPart = convertJwkToBase64(JSON_SERVER);
            //upload to server
            const res = await setPvtKeys(serverData.id, JSON_SERVER);
            // const devicePassword = sessionStorage.getItem(
            //     SESSION_STORAGE.devicePassword,
            // );
            // const privateKeyJWK = decryptPassword(newPrivateKey, devicePassword);
            // const publicKeyJWK = decryptPassword(newPublicKey, devicePassword);
            const hash_key = CryptoJS.MD5(
                JSON.stringify({
                    privateKey: newPrivateKey,
                    publicKey: newPublicKey,
                }),
            );
            if (res.data) {
                const payload = {
                    action: 'update_key_hash',
                    data: { key_hash: hash_key.toString() },
                };
                sendWebSocketData(JSON.stringify(payload));
                const devicePassword = sessionStorage.getItem(
                    SESSION_STORAGE.devicePassword,
                );
                try {
                    // if (serverData.pkey_parts) {
                    //     const { publicKey, privateKey } = serverData.pkey_parts;
                    //     publicKey_Complete = joinObjects(
                    //         files.publicKey,
                    //         publicKey,
                    //     );
                    //     privateKey_Complete = joinObjects(
                    //         files.privateKey,
                    //         privateKey,
                    //     );
                    // }
                    // const cryptoKey = await window.crypto.subtle.importKey(
                    //     'jwk',
                    //     { ...RSA_CONSTANTS('decrypt'), ...privateKey_Complete },
                    //     { name: 'RSA-OAEP', hash: { name: 'SHA-256' } },
                    //     false,
                    //     ['decrypt'],
                    // );
                    // if (cryptoKey) {
                    const encrpytedPrivateKey = await encryptPassword(
                        newPrivateKey,
                        devicePassword,
                    );
                    const encrpytedPublicKey = await encryptPassword(
                        newPublicKey,
                        devicePassword,
                    );
                    const hashKey = CryptoJS.MD5(
                        JSON.stringify(newPrivateKey),
                    ).toString();
                    dispatch(
                        updatePrivateKey({
                            id: serverData.id,
                            privateKey: encrpytedPrivateKey,
                            publicKey: encrpytedPublicKey,
                            defaultHashkey: hashKey,
                        }),
                    );
                    await handleChannelEncryption(encrpytedPublicKey);
                    await handleMessagesMigration({
                        message_encryption: MESSAGE_VERSION,
                    });
                    // }
                } catch (error) {
                    console.log('ERROR');
                }
            }
            console.log('UPLOAD SERVER KEY', res);
        }
    };
    // ------------------------

    const handleChannelEncryption = async newPublicKey => {
        channelList?.map(async item => {
            try {
                const encryptedChannelName = await encryptDataWithRSA(
                    item.name,
                    newPublicKey,
                );
                const payload = {
                    name: encryptedChannelName,
                    channel_id: item?._id,
                };

                editChannel(payload);
            } catch (error) {
                console.log('CHANNEL ENCRYPTION EERRROR', error);
            }
        });
    };

    const handleMessagesMigration = async (params?) => {
        const payload = {
            action: 'get_all_messages',
            data: { ...params },
        };
        sendWebSocketData(JSON.stringify(payload));
        dispatch(updateDecryptionLoader(true));
    };

    return {
        handleChange,
        files,
        handleAddPrivateKeys,
        channelList,
        handleUpdateMessages,
    };
};

export default useEncryptionManagement;
