import { ReactEventHandler, memo, useState } from 'react';

import { formatDuration } from '@/Utils/handleDurations';
import { ReactComponent as PlayIcon } from '@/assets/icons/play.svg';
import SaveBtn from '@/components/save-btn/SaveBtn';

import classes from './SelectedMediaDisplay.module.css';
import { VideoMediaProps } from './SelectedMediaTypes';

const VideoMedia: React.FC<VideoMediaProps> = props => {
    const { index, src, name, type = 'video/mp4' } = props;
    const [videoDuration, setVideoDuration] = useState(0);

    const onLoadedMetadataHandler: ReactEventHandler<
        HTMLVideoElement
    > = event => {
        setVideoDuration(event.currentTarget?.duration || 0);
    };
    console.log(type);
    return (
        <div className={classes['video-wrap']}>
            <video
                className={classes['video']}
                key={index}
                controls={false}
                onLoadedMetadata={onLoadedMetadataHandler}
                autoPlay={false}>
                <source src={src} type={type} />
            </video>
            <span className={classes['copy-button-container']}>
                <SaveBtn src={src} name={name} />
            </span>
            <span className={classes['video-duration']}>
                {formatDuration(videoDuration)}
            </span>
            <PlayIcon className={classes['play-icon']} />
        </div>
    );
};
export default memo(VideoMedia);
