import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IMessageData } from '@/types';

interface IMessageList {
    [channelId: number]: IMessageData;
}

interface IMessagesSlice {
    cacheMessages: IMessageList;
    messagesList: IMessageList;
    lastSeenMessage: any;
}

const initialState: IMessagesSlice = {
    cacheMessages: {},
    messagesList: {},
    lastSeenMessage: [],
};

export const MessageDataSlice = createSlice({
    name: 'messagesData',
    initialState,
    reducers: {
        updateMessageList: (
            state: IMessagesSlice,
            {
                payload,
            }: PayloadAction<{ messages: IMessageData[]; channel_id: string }>,
        ) => {
            const channelId = payload.channel_id;
            const updatedMessagesList = {
                ...(state.messagesList?.[channelId] || {}),
                ...payload.messages?.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item._id]: { ...acc[item._id], ...item },
                    };
                }, {}),
            };

            const sortedMessages = Object.values(updatedMessagesList).sort(
                (a: any, b: any) => b.created_at - a.created_at,
            );

            const slicedMessages = sortedMessages.slice(0, 30);

            return {
                ...state,
                messagesList: {
                    ...state.messagesList,
                    [channelId]: updatedMessagesList,
                },
                // cacheMessages: {
                //     ...state.cacheMessages,
                //     [channelId]: slicedMessages?.reduce(
                //         (acc: any, item: any) => {
                //             return {
                //                 ...acc,
                //                 [item._id]: { ...acc[item._id], ...item },
                //             };
                //         },
                //         {},
                //     ),
                // },
            };
        },
        updateNextMessageList: (
            state: IMessagesSlice,
            {
                payload,
            }: PayloadAction<{ messages: IMessageData[]; channel_id: string }>,
        ) => {
            const channelId = payload.channel_id;
            const updatedMessagesList = {
                ...payload.messages?.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item._id]: { ...acc[item._id], ...item },
                    };
                }, {}),
                ...(state.messagesList?.[channelId] || {}),
            };

            return {
                ...state,
                messagesList: {
                    ...state.messagesList,
                    [channelId]: updatedMessagesList,
                },
            };
        },
        changeMessagesList(
            state: IMessagesSlice,
            {
                payload,
            }: PayloadAction<{ messages: IMessageData[]; channel_id: string }>,
        ) {
            const channelId = payload.channel_id;

            const MessagesListArray = payload.messages
                ?.reduce((acc, item) => {
                    acc.push({ [item._id]: { ...item } });
                    return acc;
                }, [])
                .reverse();

            const MessagesListObject = MessagesListArray?.reduce((acc, obj) => {
                const key = Object.keys(obj)[0];
                acc[key] = obj[key];
                return acc;
            }, {});

            return {
                ...state,
                messagesList: {
                    ...state.messagesList,
                    [channelId]: MessagesListObject,
                },
            };
        },

        addMessage: (
            { messagesList, cacheMessages }: IMessagesSlice,
            { payload }: PayloadAction<IMessageData>,
        ) => {
            const messageList = messagesList[payload?.channel_id] ?? {};
            const messageId = payload?._id;

            if (messageList && cacheMessages) {
                if (!messagesList[payload?.channel_id]?.[messageId]) {
                    messagesList[payload?.channel_id] = {
                        [payload._id]: payload,
                        ...messagesList[payload.channel_id],
                    };
                }
                // if (!cacheMessages[payload?.channel_id]?.[messageId]) {
                //     cacheMessages[payload?.channel_id] = {
                //         [payload._id]: payload,
                //         ...cacheMessages[payload.channel_id],
                //     };
                // }
            } else {
                messagesList[payload?.channel_id] = {
                    ...messagesList,
                    [payload?.channel_id]: { [payload._id]: payload },
                };
                // cacheMessages[payload?.channel_id] = {
                //     ...cacheMessages,
                //     [payload?.channel_id]: { [payload._id]: payload },
                // };
            }
        },

        updateMessageThread: (
            state: IMessagesSlice,
            {
                payload,
            }: PayloadAction<{
                lastMessage?: any;
                messageCount?: number;
                messageId?: string;
                isThreadMember?: boolean;
                isThreadMute?: boolean;
                channel_id?: string;
                thread_id?: string;
            }>,
        ) => {
            const { channel_id, thread_id } = payload?.lastMessage || {};
            let message_id = payload.messageId;
            if (
                state.messagesList[channel_id || payload?.channel_id][
                    message_id
                ]
            ) {
                state.messagesList[channel_id || payload?.channel_id][
                    message_id
                ] = {
                    ...state.messagesList[channel_id || payload?.channel_id][
                        message_id
                    ],
                    thread_last_message: payload?.lastMessage,
                    total_thread_message_count: payload?.messageCount,
                    // is_thread_member: payload.isThreadMember,
                    is_thread_mute: payload.isThreadMute,
                    thread_id: thread_id || payload?.thread_id,
                };
            }
        },

        editMessage({ messagesList }: IMessagesSlice, { payload }) {
            messagesList[payload.channel_id][payload.message_id] = {
                ...messagesList[payload.channel_id][payload.message_id],
                message: payload.msg,
            };
        },

        updateMessage({ messagesList }: IMessagesSlice, { payload }) {
            messagesList[payload.channel_id][payload._id] = payload;
        },

        deleteMessage({ messagesList }: IMessagesSlice, { payload }) {
            delete messagesList[payload?.channel_id]?.[payload.message_id];
        },

        editMsgReaction({ messagesList }: IMessagesSlice, { payload }) {
            const reactionData = payload.reactionData;
            // I converted the object message list into an array.
            // Here I select the object whose sender_message_id matches the 'message_id in reaction'.
            // I updated the channel_id and message_id of the selected object with the emoji values in redux.
            // 1-
            const messageList = messagesList[reactionData.channel_id] || {};
            const messagesArray = Object.values(messageList).reverse();
            // 2-
            const filterSenderMessage = messagesArray.find(
                item => item.sender_message_id === reactionData.message_id,
            );
            // 3-
            const filterSenderMessageId =
                filterSenderMessage?._id || reactionData.message_id;
            const filterSenderChannelId =
                filterSenderMessage?.channel_id || reactionData.channel_id;
            //FILTER END

            if (
                messageList &&
                messageList[filterSenderMessageId] !== undefined
            ) {
                const messageReactions =
                    messageList[filterSenderMessageId]?.reactions || [];
                const targetReactionIndex = messageReactions.findIndex(
                    (el: { emoji: string }) => el.emoji === reactionData.emoji,
                );

                // Check if the user has already added the same emoji
                const userAddedEmoji = messageReactions.some(reaction =>
                    reaction.list.some(
                        userReaction =>
                            userReaction.user_id === reactionData.user_id &&
                            reaction.emoji === reactionData.emoji,
                    ),
                );

                if (payload.type === 'DELETE' && targetReactionIndex !== -1) {
                    const reactionList =
                        messageReactions[targetReactionIndex].list;

                    if (reactionList.length === 1) {
                        messageReactions.splice(targetReactionIndex, 1);
                    } else {
                        const updatedReactionList = reactionList.filter(
                            (reaction: { _id: string }) =>
                                reaction._id !== reactionData._id,
                        );
                        messageReactions[targetReactionIndex].list =
                            updatedReactionList;
                    }
                } else if (payload.type === 'ADD' && filterSenderChannelId) {
                    if (!userAddedEmoji) {
                        if (messageReactions.length > 0) {
                            if (targetReactionIndex === -1) {
                                const newReaction = {
                                    emoji: reactionData.emoji,
                                    list: [
                                        {
                                            user_id: reactionData.user_id,
                                            _id: reactionData._id,
                                        },
                                    ],
                                };
                                messageReactions.unshift(newReaction);
                            } else {
                                const reactionList =
                                    messageReactions[targetReactionIndex].list;
                                const updatedReactionList = [
                                    {
                                        user_id: reactionData.user_id,
                                        _id: reactionData._id,
                                    },
                                    ...reactionList,
                                ];
                                messageReactions[targetReactionIndex].list =
                                    updatedReactionList;
                            }
                        } else {
                            const newMessageReactions = [
                                {
                                    emoji: reactionData.emoji,
                                    list: [
                                        {
                                            user_id: reactionData.user_id,
                                            _id: reactionData._id,
                                        },
                                    ],
                                },
                            ];

                            messageList[filterSenderMessageId].reactions =
                                newMessageReactions;
                        }
                    }
                }
            }
        },

        mapReactions(state, { payload }: PayloadAction<any[]>) {
            payload.forEach(item => {
                // I converted the object message list into an array.
                // Here I select the object whose sender_message_id matches the 'message_id in reaction'.
                // I updated the channel_id and message_id of the selected object with the emoji values in redux.
                // 1-
                const messageList = state.messagesList[item.channel_id] || {};
                const messagesArray = Object.values(messageList).reverse();
                // 2-
                const filterSenderMessage = messagesArray.find(
                    itemFind =>
                        itemFind?.sender_message_id === item?.message_id,
                );
                // 3-
                const filterSenderMessageId =
                    filterSenderMessage?._id || item.message_id;
                //FILTER END

                if (
                    state &&
                    messageList &&
                    messageList[filterSenderMessageId]
                ) {
                    const messageReactions =
                        messageList[filterSenderMessageId].reactions || [];

                    // Check if the emoji already exists
                    const existingEmojiIndex = messageReactions.findIndex(
                        e => e.emoji === item.emoji,
                    );

                    // If the emoji doesn't exist, add it with the user's reaction
                    if (existingEmojiIndex === -1) {
                        messageList[filterSenderMessageId].reactions = [
                            {
                                emoji: item.emoji,
                                list: [
                                    { user_id: item.user_id, _id: item._id },
                                ],
                            },
                            ...messageReactions,
                        ];
                    } else {
                        // If the emoji exists, check if the user has already reacted
                        const userReacted = messageReactions[
                            existingEmojiIndex
                        ].list.some(
                            (reaction: { user_id: string }) =>
                                reaction.user_id === item.user_id,
                        );

                        // If the user hasn't reacted, add the reaction
                        if (!userReacted) {
                            messageList[filterSenderMessageId].reactions[
                                existingEmojiIndex
                            ].list.push({
                                user_id: item.user_id,
                                _id: item._id,
                            });
                        }
                    }
                }
            });
        },

        removeChannelMessages(state, { payload }: PayloadAction<string[]>) {
            payload.forEach(item => {
                if (state.messagesList[item] || state.cacheMessages[item]) {
                    delete state.messagesList?.[item];
                    delete state.cacheMessages?.[item];
                }
            });
        },
        addLastSeenMessage: (state, action: PayloadAction<any>) => {
            state.lastSeenMessage = action.payload;
        },
    },
});

export const {
    editMsgReaction,
    updateMessageList,
    updateNextMessageList,
    addMessage,
    editMessage,
    updateMessage,
    deleteMessage,
    changeMessagesList,
    removeChannelMessages,
    mapReactions,
    addLastSeenMessage,
    updateMessageThread,
    // updateLastSeenMessage
} = MessageDataSlice.actions;
export default MessageDataSlice.reducer;
