import {
    editChannel,
    editChannelsPriority,
    getChannelListAction,
} from '@/Utils/socket-actions/ChannelActions/channelActions';

export const onDragEnd = (result, columns) => {
    const { source, destination } = result;

    const movedChIndex = destination.index;
    if (
        !destination ||
        (source.index === movedChIndex &&
            source.droppableId === destination.droppableId)
    ) {
        console.log('Channel droped outside the list or at the same position');
        return;
    }

    let newSections = columns;
    let channelPriority = 0;

    const targetChSection = columns.find(
        sect => sect._id === source.droppableId,
    );
    const indexOfSourceSection = columns.findIndex(
        sect => sect._id === source.droppableId,
    );

    const indexOfDestSection = columns.findIndex(
        sect => sect._id === destination.droppableId,
    );

    // get the channels in the target section
    const targetChListCopy = targetChSection.channelList;

    const movedCh = targetChListCopy.find(
        (_, key: number) => key === source.index,
    );

    const destSection = columns.find(
        sect => sect._id === destination.droppableId,
    );
    const removedIndex = targetChListCopy.findIndex(
        (_, key) => key === source.index,
    );
    targetChListCopy.splice(removedIndex, 1);
    newSections[indexOfSourceSection].channelList = targetChListCopy;

    let destItems = destSection.channelList;
    destItems.splice(movedChIndex, 0, movedCh);
    newSections[indexOfDestSection].channelList = destItems;

    let arrangeArray = destItems;
    if (arrangeArray[movedChIndex + 1]?.priority) {
        channelPriority = arrangeArray[movedChIndex + 1].priority + 1;
    }

    arrangeArray[movedChIndex] = {
        ...arrangeArray[movedChIndex],
        priority: channelPriority,
        channel_section_id:
            destSection._id === 'UNDEFINED_SECTION' ? '' : destSection._id,
    };

    arrangeArray = arrangeArray.slice(movedChIndex);

    for (let i = 0; i < arrangeArray.length - 1; i++) {
        const currentElement = arrangeArray[i];
        const nextElement = arrangeArray[i + 1];

        if (!currentElement.hasOwnProperty('priority')) {
            arrangeArray[i] = { ...arrangeArray[i], priority: 1 };
        }

        if (!nextElement.hasOwnProperty('priority')) {
            arrangeArray[i + 1] = {
                ...arrangeArray[i + 1],
                priority: arrangeArray[i].priority + 1,
            };
        } else if (nextElement.priority <= currentElement.priority) {
            arrangeArray[i + 1] = {
                ...arrangeArray[i + 1],
                priority: arrangeArray[i].priority + 1,
            };
        }
    }

    // firt channel needs edit section id (if moved to other section)
    const targetChannelConfig = {
        channel_id: arrangeArray[0]._id,
        channel_section_id: arrangeArray[0].channel_section_id
            ? arrangeArray[0].channel_section_id
            : '',
        priority: arrangeArray[0].priority,
    };

    let configData = [];
    arrangeArray.forEach(element => {
        configData.push({
            channel_id: element._id,
            priority: element.priority,
        });
    });
    editChannel(targetChannelConfig);
    editChannelsPriority(configData);

    setTimeout(() => {
        getChannelListAction();
    }, 500);
};
