import React from 'react';
import { Link } from 'react-router-dom';

type Props = {};

const Page404 = (props: Props) => {
    return (
        <>
            <h1>404 Ooops!</h1>
            <p>this link is not correct</p>
            <Link to="/">Go Home</Link>
        </>
    );
};

export default Page404;
