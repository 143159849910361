import { Trans } from '@lingui/macro';
import { FC } from 'react';

import { sendMessage } from '@/Utils/socket-actions/MessageActions/messageActions';
import mailIcon from '@/assets/icons/mail.svg';
import trashIcon from '@/assets/icons/trash.svg';
import { useAppDispatch } from '@/hooks/useRedux';
import { deleteMessage } from '@/store/messages-reducer/messages-reducer';

import classes from './Popup.module.css';

type Props = { modalRef; messageItem };

const SendErrorPopup: FC<Props> = props => {
    const { modalRef, messageItem } = props;
    const dispatch = useAppDispatch();

    const sendErrorPopupData = [
        {
            icon: mailIcon,
            label: (
                <p>
                    <Trans>Retry</Trans>
                </p>
            ),
            onPress: item => {
                dispatch(
                    deleteMessage({
                        channel_id: messageItem.channel_id,
                        message_id: messageItem._id,
                    }),
                );
                const message = {
                    channel_mentions: messageItem.channel_mentions,
                    mention_id: messageItem.mention_id,
                    message: messageItem.message,
                    replyData: messageItem.replyData,
                    type: messageItem.type,
                };
                sendMessage(messageItem.type, message, messageItem.mention_id);
            },
        },
        {
            icon: trashIcon,
            label: (
                <p style={{ color: '#fb433a' }}>
                    <Trans>Delete</Trans>
                </p>
            ),
            onPress: () => {
                dispatch(
                    deleteMessage({
                        channel_id: messageItem.channel_id,
                        message_id: messageItem._id,
                    }),
                );
            },
        },
    ];

    return (
        <div
            style={{ right: 30 }}
            className={`${classes['owner-image-popup-container']}`}
            ref={modalRef}>
            <div className={classes['owner-image-popup']}>
                {sendErrorPopupData?.map((item, index) => (
                    <div
                        key={index}
                        onClick={item.onPress}
                        className={classes['owner-image-popup-row']}>
                        <img alt="" height={18} width={18} src={item?.icon} />
                        <p>{item?.label}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SendErrorPopup;
