import { Trans, t } from '@lingui/macro';
import { useEffect, useRef } from 'react';
import Select from 'react-select';

import { AppButton, SearchInput } from '@/UI';
import TabsManagement from '@/UI/TabsManagement/TabsManagement';
import checkedSVG from '@/assets/icons/check-blue-circle.svg';
import closeSVG from '@/assets/icons/close-header.svg';
import { DEFAULT_IMAGES } from '@/constants';
import { BUTTON_SIZE, BUTTON_TYPE } from '@/constants/enum';
import { IServerData } from '@/types';

import { addMemberSelectStyles } from '../organization-create-member/selectStyles';

import classes from './OrganizationAddMember.module.css';
import useOrganizationAddMember from './useOrganizationAddMember';

type Props = {
    onClose: () => void;
    user: any;
    organizationId: string;
    organizationList: any[];
    server: any[];
};

const OrganizationAddMembers = (props: Props) => {
    const { user, organizationList } = props;
    const {
        selectedServer,
        handleContinue,
        data,
        filterData,
        handleOnClose,
        searchText,
        successMessage,
        handleServerClick,
        tabs,
        onChangeTab,
        selectedTabItem,
        organizationIdForDeleteServerList,
        setOrganizationIdForDeleteServerList,
        memberAllServer,
    } = useOrganizationAddMember(props);

    const _renderHeader = () => (
        <div className={classes['header']}>
            <div className={classes['heading-wrapper']}>
                <img
                    alt={user?.name ?? ''}
                    src={user.profile_picture ?? DEFAULT_IMAGES.PROFILE_IMAGE}
                />
                <div className={classes['text-wrapper']}>
                    <h2>{user.name}</h2>
                    <span>{user.username}</span>
                </div>
            </div>
            <img
                alt={t`close`}
                src={closeSVG}
                onClick={handleOnClose}
                style={{ cursor: 'pointer' }}
            />
        </div>
    );

    const _renderServerItem = (item: IServerData | any) => {
        const PROFILE_PICTURE =
            item?.settings.logo ?? DEFAULT_IMAGES.SERVER_IMAGE;

        const IS_ACTIVE = selectedServer?.some(
            rowItem => rowItem?.id === item?.id,
        );

        return (
            <div
                className={`${classes['server-column']} ${
                    IS_ACTIVE ? classes['active'] : ''
                }`}
                onClick={() => {
                    handleServerClick(item);
                }}>
                <img src={PROFILE_PICTURE} alt="" />
                <div className={classes['server-column-text']}>
                    <span className={classes['server-name']}>{item.name}</span>
                    <span className={classes['server-sub']}>
                        {item.organization_name ?? ''}
                    </span>
                </div>
                {IS_ACTIVE && (
                    <img
                        alt="checked"
                        src={checkedSVG}
                        className={classes['checked-icon']}
                    />
                )}
            </div>
        );
    };

    const _renderButtons = () => (
        <div className={classes['button-wrapper']}>
            <AppButton
                size={BUTTON_SIZE.small}
                buttonType={BUTTON_TYPE.transparent}
                title={t`Cancel`}
                onClick={handleOnClose}
            />
            <AppButton
                size={BUTTON_SIZE.medium}
                buttonType={BUTTON_TYPE.primary}
                title={t`Save`}
                style={{ width: '100%' }}
                onClick={handleContinue}
            />
        </div>
    );

    const containerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                handleOnClose();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [containerRef, handleOnClose]);
    let serversListOptions = organizationList?.map(item => {
        return {
            value: item?.id,
            label: item?.name,
        };
    });
    serversListOptions.unshift({ value: 'allServers', label: t`All Servers` });

    return (
        <div className={classes['container']} ref={containerRef}>
            {_renderHeader()}
            <div className={classes['content-container']}>
                <TabsManagement tabs={tabs} onChangeTab={onChangeTab} />
                {selectedTabItem?.value === 'add' && (
                    <span>
                        <Trans>
                            Select the server where you want to add the user
                        </Trans>
                    </span>
                )}
                {selectedTabItem?.value === 'delete' && (
                    <div style={{ width: '100%' }}>
                        <p
                            style={{
                                fontSize: 12,
                            }}>
                            <Trans>
                                Select the server where you want to delete the
                                user
                            </Trans>
                        </p>
                        <div>
                            <label
                                style={{
                                    opacity: 0.5,
                                    fontSize: 12,
                                    lineHeight: '26px',
                                }}>
                                <Trans>Select Organization</Trans>*
                            </label>
                            <Select
                                isDisabled={memberAllServer.length === 0}
                                name="organizationId"
                                isSearchable
                                styles={addMemberSelectStyles}
                                placeholder={t`Select Section`}
                                className={`${classes['select']}`}
                                onChange={e =>
                                    setOrganizationIdForDeleteServerList(
                                        e.value,
                                    )
                                }
                                options={serversListOptions}
                            />
                            {/* <select
                                disabled={memberAllServer.length === 0}
                                style={{
                                    border: '2px solid rgb(255,255,255,0.2)',
                                    width: '100%',
                                    marginTop: 4,
                                    cursor:
                                        memberAllServer.length === 0
                                            ? 'not-allowed'
                                            : 'pointer',
                                }}
                                value={organizationIdForDeleteServerList}
                                onChange={e =>
                                    setOrganizationIdForDeleteServerList(
                                        e.target.value,
                                    )
                                }
                                name="organizationId">
                                <option key="allServers" value="allServers">
                                    <Trans>All Servers</Trans>
                                </option>
                                {organizationList?.map((item, key) => (
                                    <option key={key} value={item?.id}>
                                        {item?.name}
                                    </option>
                                ))}
                            </select> */}
                        </div>
                    </div>
                )}
                <div className={classes['search-wrapper']}>
                    <SearchInput
                        onChange={filterData}
                        autoFocus={false}
                        id="SearchInput"
                        value={searchText}
                        placeholder={t`Search Server`}
                        style={{ width: '332px' }}
                    />
                </div>

                <div className={classes['server-list-container']}>
                    <div
                        className={`${classes['form-success']} ${
                            successMessage !== ''
                                ? classes['visible']
                                : classes['invisible']
                        }`}>
                        <span>{successMessage}</span>
                    </div>
                    {data?.length === 0 ? (
                        <p style={{ opacity: 0.3 }}>
                            <Trans>No server could not be found</Trans>!
                        </p>
                    ) : (
                        <>{data?.map(item => _renderServerItem(item))}</>
                    )}
                </div>
                {_renderButtons()}
            </div>
        </div>
    );
};

export default OrganizationAddMembers;
