// UNIMPORTED - DON"T REMOVE

import { Trans, t } from '@lingui/macro';

import { AppButton, ConfirmationModal, TextInput, Version } from '@/UI';
import Success from '@/assets/icons/success.svg';
import logo from '@/assets/images/login_logo.png';

import classes from './ResetPassword.module.css';
import useResetPassword from './useResetPassword';

const ResetPassword = () => {
    const { modalVisible, onCancelModal, handleReset, onChange, email } =
        useResetPassword();

    return (
        <div className="justifyCenter">
            <div className="centerContainer">
                <div className={classes['contentWrapper']}>
                    <img alt="" src={logo} className={classes['logo']} />

                    <div className={classes['headingWrapper']}>
                        <span className="primaryHeading marginVertical-small">
                            <Trans>Reset Password</Trans>
                        </span>
                        <span className="subHeading margin text-sm-center">
                            <Trans>
                                You can reset your password via e-mail from this
                                screen
                            </Trans>
                            .
                        </span>
                    </div>
                    <div className={classes['inputWrapper']}>
                        <TextInput
                            name="email"
                            placeHolder={t`Enter Your Email`}
                            label={t`Email`}
                            onChange={onChange}
                            error={email.error}
                        />
                    </div>
                    <AppButton
                        title={t`Reset Password`}
                        onClick={handleReset}
                    />
                    <Version />
                </div>
            </div>
            <ConfirmationModal
                visible={modalVisible}
                onCancel={onCancelModal}
                title={t`Reset Password`}
                description={t`Email is sent with reset link \n You are being redirected to Login`}
                hideButton={true}
                icon={Success}
            />
        </div>
    );
};

export default ResetPassword;
