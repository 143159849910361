import SOCKET_ACTIONS_WITH_TYPES from '@/constants/socketActions';
import store from '@/store';
import { addMessage } from '@/store/messages-reducer/messages-reducer';
import { userPreferencesActions } from '@/store/user-preferences-slice';
import { IMessageData } from '@/types';

import {
    encryptDataWithAES,
    encryptDataWithRSA,
    exportKeyToJWK,
    generateAESKey,
} from '../../cryptoUtils';
import { onDecryptMessage } from '../../onDecryptMessage';
import { sendSearchData, sendWebSocketData } from '../../webSocketUtils';

export const onEncryptMessage = async data => {
    const state = store.getState().serverDataReducer;
    const cryptoKeys = store.getState().cryptoKeyReducer;
    let publicKey = state.serverData[state.activeServer]?.publicKey;
    if (data.key_hash) {
        publicKey = cryptoKeys[data.key_hash]?.publicKey;
    }

    // generates Random AES key to encrypt data
    const AESKey = await generateAESKey();
    const { encryptedData } = await encryptDataWithAES(
        JSON.stringify(data),
        AESKey,
    );

    const AESKeyJwk = await exportKeyToJWK(AESKey);
    const token = AESKeyJwk;
    const encryptToken = await encryptDataWithRSA(token, publicKey);

    return { encryptedData, encryptToken };
};

const handleMessage = async (type, data, action = '') => {
    const { encryptedData, encryptToken } = await onEncryptMessage(data);

    const state = store.getState().serverDataReducer;
    let defaultAction = SOCKET_ACTIONS_WITH_TYPES.chat.action;
    let defaultHashKey = state.serverData[state.activeServer]?.defaultHashkey;

    if (action) {
        defaultAction = action;
    }
    if (data.key_hash) {
        defaultHashKey = data.key_hash;
    }
    const payloadData = {
        message: encryptedData,
        token: encryptToken,
        type: type,
        encryption_version: 2,
        key_hash: defaultHashKey,
        selected_message_id: data?.replyData?.original_message_id ?? null,
    };
    const payload = {
        action: defaultAction,
        data: payloadData,
    };
    return payload;
};

export const sendMessage = async (
    type,
    data,
    mentionArray,
    // mentionArrayRoles,
    action = '',
) => {
    const cryptoKeys = store.getState().cryptoKeyReducer;
    const { _persist, ...filteredObj } = cryptoKeys;

    const preferedHash = store.getState().userPreferencesReducer.msg_key_hash;
    const keyIsThere = Object.keys(filteredObj).find(
        item => item === preferedHash,
    );
    let sentMessage = data;
    if (preferedHash && preferedHash?.trim() !== '' && keyIsThere) {
        sentMessage = { ...data, key_hash: preferedHash };
    } else {
        store.dispatch(userPreferencesActions.updateMsgKeyHash(null));
    }
    let payload: any = await handleMessage(type, sentMessage, action);

    const state = store.getState().serverDataReducer;
    const user = store.getState().userReducer.user;
    const privateKey = state.serverData[state.activeServer]?.privateKey;
    let decryptData = await onDecryptMessage(payload.data, privateKey);

    if (decryptData.is_encrypt) {
        let decryptionAttempts = 0;

        for (let index = 0; index < 3; index++) {
            payload = await handleMessage(type, sentMessage, action);
            decryptData = await onDecryptMessage(payload.data, privateKey);

            if (decryptData.is_encrypt) {
                decryptionAttempts++;
            } else {
                // Decrypt successful, break out of the loop
                break;
            }
        }
        if (decryptionAttempts === 3) {
            // Add fail case logic here when decryption fails 3 times
            const id = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
            const addMessagePayload: IMessageData = {
                ...sentMessage,
                type: type,
                mention_id: mentionArray ?? [],
                encryption_version: 2,
                _id: id,
                channel_id: state.activeChannelId?.[state.activeServer],
                user_id: user.id,
                created_at: Date.now(),
                sending_error: true,
            };
            store.dispatch(addMessage(addMessagePayload));
            throw new Error('Decryption failed 3 times. Handling fail case...');
        }
    }

    sendWebSocketData(JSON.stringify(payload));
};

export const reactionCreate = async (reaction: {
    message_id: string;
    emoji: string;
    messageItem?: { sender_channel_id?: string | undefined | null };
}) => {
    const config = {
        action: SOCKET_ACTIONS_WITH_TYPES.reactCreate.action,
        data: {
            emoji: reaction.emoji,
            message_id: reaction.message_id,
        },
    };
    sendWebSocketData(JSON.stringify(config));
};

export const deleteReaction = async (
    reactionId: string,
    messageItem: IMessageData,
) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.reactionDelete.action,
        data: { reaction_id: reactionId, message_id: messageItem._id },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const wsEditMessage = async (msg: { id: string; message: string }) => {
    const { encryptedData, encryptToken } = await onEncryptMessage({
        message: msg.message,
    });

    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.messageEdit.action,
        data: {
            message_id: msg.id,
            message: encryptedData,
            token: encryptToken,
        },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const wsDeleteMessage = (msgId: string) => {
    const data = {
        action: SOCKET_ACTIONS_WITH_TYPES.messageDelete.action,
        data: { message_id: msgId },
    };
    sendWebSocketData(JSON.stringify(data));
};

export const lastSeenMessage = data => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.messageLastseen.action,
        data: {
            message_id: data[0]?._id,
        },
    };
    if (data[0]?._id) {
        sendWebSocketData(JSON.stringify(payload));
    }
};

export const sendSearchMessagesDesktop = messagesArray => {
    let payload = {
        event: 'registerMessages',
        data: { Messages: messagesArray },
    };
    sendSearchData(JSON.stringify(payload));
};

export const sendWsMessageRange = messageId => {
    const payload = {
        action: SOCKET_ACTIONS_WITH_TYPES.messageRange.action,
        data: {
            message_id: messageId,
        },
    };
    sendWebSocketData(JSON.stringify(payload));
};
