import { Navigate } from 'react-router-dom';

import logo from '@/assets/images/login_logo.png';
import { useAppSelector } from '@/hooks/useRedux';
import { ROOT_PATH } from '@/routes/router.types';
import { privateKeyArray } from '@/store/server-data-reducer/selectors-server-data';

import packageInfo from '../../../package.json';

import classes from './DevicePassword.module.css';
import PutDevicePassword from './devicePassword-sections/PutDevicePassword/PutDevicePassword';

type Props = {};

const DevicePassword = (props: Props) => {
    const isPrivateKeyAvailable = useAppSelector(state =>
        privateKeyArray(state),
    );

    return (
        <div className="justifyCenter">
            <div className="centerContainer">
                <div className={classes['contentWrapper']}>
                    <img alt="" src={logo} style={{ height: '25vh' }} />
                    {isPrivateKeyAvailable.length > 0 ? (
                        <PutDevicePassword />
                    ) : (
                        <Navigate to={ROOT_PATH.login} />
                    )}
                    <span className={classes['version']}>
                        Covchat version {packageInfo.version} <br /> 2023
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DevicePassword;
