import { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import ImgButton from '@/UI/custom-buttons/ImgButton';
import EmojiKeyBoard from '@/UI/emoji-keyboard/EmojiKeyBoard';
import attach from '@/assets/icons/attach.svg';
import EmojiIcon from '@/assets/icons/emojiPicker.svg';
import { ReactComponent as SendIcon } from '@/assets/icons/send.svg';

import classes from './NewMessageForm.module.css';
import AttachmentMenu from './attachment-menu/AttachmentMenu';
import InputControllers from './input-controllers/InputControllers';
import MentionsInput from './mentions/MentionsInput';
import ReplyMessage from './reply-message/ReplyMessage';
import useNewMessageForm from './useNewMessageForm';

const NewMessageForm = props => {
    const {
        isAttachMenu,
        submitAttachmentMessageHandler,
        pastedFiles,
        emojiIsShown,
        selectEmojiHandler,
        submitMessasgeHandler,
        closeReplyHandler,
        toggleAddMediaHandler,
        inputRef,
        onInputFocus,
        msgInputValue,
        inputChangeHanlder,
        toggleEmojiBoardHandler,
        isBtnDisabled,
        mentionsModal,
        mentionsChannelModal,
        handleKeyPress,
        onMentionsSubmit,
        onMentionsChannelSubmit,
        onMentionsRoleSubmit,
        mentionsData,
        channelsData,
        rolesData,
        members,
        handleKeyDown,
        handlePaste,
        handleController,
        mentionsRoleModal,
        mentionText,
    } = useNewMessageForm(props);

    const [reply, setReply] = useState(false);
    const [replyTo, setReplyTo] = useState<any>({});

    useEffect(() => {
        setReply(props.isReply);
    }, [props.isReply]);

    useEffect(() => {
        setReplyTo(props.replyTo);
    }, [props.replyTo]);

    useEffect(() => {
        if (props.mediaUploaded && reply) {
            setReply(false);
            setReplyTo({});
        }
    }, [props.mediaUploaded, reply]);

    const CONTENT_EDITABLE_PROPS = {
        onFocus: onInputFocus,
        className: classes['NewMessageInput'],
        innerRef: inputRef,
        html: `${msgInputValue}`,
        disabled: false,
        onChange: inputChangeHanlder,
        tagName: 'article',
        id:
            props?.inputType === 'threadInput'
                ? 'contentEditable2'
                : 'contentEditable',
        onKeyDown: event => handleKeyDown(event),
        onPaste: handlePaste,
    };

    return (
        <div className={classes['form-container']}>
            {isAttachMenu && (
                <AttachmentMenu
                    mediaUploaded={props.mediaUploaded}
                    onToggleVisibility={toggleAddMediaHandler}
                    onSendMessage={submitAttachmentMessageHandler}
                    passDefaultFiles={
                        pastedFiles?.length > 0 ? pastedFiles : false
                    }
                    reply={reply ? replyTo : false}
                    uploadProgress={props?.uploadProgress}
                />
            )}
            {emojiIsShown && <EmojiKeyBoard onSelect={selectEmojiHandler} />}
            {mentionsModal && (
                <MentionsInput
                    onSubmit={onMentionsSubmit}
                    data={mentionsData}
                    mentionText={mentionText}
                    filterKey="username"
                />
            )}
            {mentionsChannelModal && (
                <MentionsInput
                    onSubmit={onMentionsChannelSubmit}
                    data={channelsData}
                    mentionText={mentionText}
                    filterKey="name"
                />
            )}
            {mentionsRoleModal && (
                <MentionsInput
                    onSubmit={onMentionsRoleSubmit}
                    data={rolesData}
                    mentionText={mentionText}
                    filterKey="name"
                />
            )}
            <div style={{ display: 'flex' }}>
                <InputControllers
                    inputRef={inputRef}
                    handleController={handleController}
                />
            </div>
            <form onSubmit={submitMessasgeHandler}>
                {reply && !isAttachMenu && (
                    <ReplyMessage
                        replyData={replyTo}
                        onCloseReply={closeReplyHandler}
                    />
                )}
                <ImgButton
                    type="button"
                    className={classes['attachment']}
                    onClick={toggleAddMediaHandler}
                    icon={attach}
                    disabled={false}
                />
                <ContentEditable {...CONTENT_EDITABLE_PROPS} />

                <div className={classes['btns-container']}>
                    <ImgButton
                        type="button"
                        onClick={toggleEmojiBoardHandler}
                        icon={EmojiIcon}
                        disabled={false}
                    />
                    <ImgButton
                        type="submit"
                        disabled={isBtnDisabled}
                        style={{ fill: '#ffffff' }}
                        className={classes.sendBTN}>
                        <SendIcon
                            className={
                                isBtnDisabled
                                    ? classes['disactive']
                                    : classes['active']
                            }
                        />
                    </ImgButton>
                </div>
            </form>
        </div>
    );
};
export default NewMessageForm;
